<template>
    <v-container fluid class="align-start justify-start" fill-height>
        <v-row style="flex: 1 0 100%">
            <v-col>
                <v-card>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-avatar color="success" size="54">
                                <v-icon dark size="38">
                                    mdi-account-check
                                </v-icon>
                            </v-avatar>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <div style="font-size: .9rem; letter-spacing: 1px;">Активовано:</div>
                            <div class="py-1">
                                <span class="card-percent">
                                    {{ stats.flats_active_percent }}%
                                </span>
                                <span class="card-count"> / {{ stats.flats_activate }}</span>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-avatar color="secondary" size="54">
                                <v-icon dark size="38">
                                    mdi-account-lock-open-outline
                                </v-icon>
                            </v-avatar>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <div style="font-size: .9rem; letter-spacing: 1px;">Не активовано:</div>
                            <div class="py-1">
                                <span class="card-percent">
                                    {{ stats.flats_not_active_percent }}%
                                </span>
                                <span class="card-count"> / {{ stats.flats_not_activate }}</span>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-avatar color="error lighten-1" size="54">
                                <v-icon dark size="38">
                                    mdi-account-off-outline
                                </v-icon>
                            </v-avatar>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <div style="font-size: .9rem; letter-spacing: 1px;">Не зареєстровано:</div>
                            <div class="py-1">
                                <span class="card-percent">
                                    {{ stats.flats_not_registered_percent }}%
                                </span>
                                <span class="card-count"> / {{ stats.flats_not_registered }}</span>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>
        <v-row style="flex: 1 1 100%; height: calc(100% - 96px)">
            <v-col cols="12" sm="5" md="4">
                <v-card class="fill-height">
                    <v-card-text>
                        <div class="pt-1 py-5">Налаштування відображення</div>
                        <v-row class="dense-wrapper">
                          <v-col cols="12">
                            <AddressElementSelect v-model="filters.city"
                                                  label="Населений пункт" filled
                                                  select_type="city"
                            />
                          </v-col>
                          <v-col cols="12">
                            <AddressElementSelect :parent_id="filters.city" v-model="filters.streets"
                                                  label="Вулиця(-ки)" multiple filled
                                                  select_type="street" use_parent_id
                                                  :disabled="!filters.city"
                            />
                          </v-col>
                          <v-col cols="12">
                            <AddressElementSelect :parent_id="filters.streets" v-model="filters.buildings"
                                                  label="Будинок(-ки)" multiple filled
                                                  select_type="building" use_parent_id
                                                  :disabled="!filters.streets.length"
                            />
                          </v-col>
                            <v-col cols="12">
                                <v-select :items="filters.status_list" v-model="filters.status"
                                          label="Статус" hide-details color="grey" filled
                                          clearable
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-radio-group
                                    v-model="filters.with_email"
                                    row
                                >
                                    <v-radio
                                        label="Усі"
                                        :value="null"
                                        color="success"
                                    ></v-radio>
                                    <v-radio
                                        label="З email"
                                        :value="true"
                                        color="success"
                                    ></v-radio>
                                    <v-radio
                                        label="Без email"
                                        :value="false"
                                        color="success"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12">
                                <v-btn class="mb-3" color="white" block @click.stop="accept_filter">
                                    Застосувати фільтр
                                </v-btn>
                                <v-btn class="button-accept-1 text--black"
                                       color="secondary lighten-2"
                                       :loading="loading"
                                       block @click.stop="deleteDialog">
                                    Надіслати запрошення
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="7" md="8">
                <v-card class="fill-height" style="display: flex; flex-direction: column">
                    <v-card-title>
                        <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Пошук"
                                color="grey darken-1"
                                placeholder="Почніть вводити текст для пошуку"
                                single-line
                                hide-details
                                class="px-4 mb-3"
                                @input="searchFlatByText"
                        ></v-text-field>
                    </v-card-title>
                    <v-card-text style="flex: 1 1 100%">
                        <v-data-table
                                :headers="headers"
                                :items="flats"
                                :items-per-page="15"
                                no-data-text="Дані відсутні. Жодного запису не виявлено"
                                :footer-props="{
                                                  showFirstLastPage: true,
                                                  itemsPerPageText: 'Рядків на сторінку',
                                                  itemsPerPageOptions: [5, 15, 30, -1],
                                                }"
                                class="custom-table small"
                        >
                            <template v-slot:item.person_id="{ item }">
                                <v-icon class="mr-2" color="success" size="22"
                                        v-if="item.status === 'Активовано'">
                                    mdi-account-check
                                </v-icon>
                                <v-icon class="mr-2" color="secondary" size="22"
                                        v-if="item.status === 'Не активовано'">
                                    mdi-account-lock-open-outline
                                </v-icon>
                                <v-icon class="mr-2" color="error lighten-1" size="22"
                                        v-if="item.status === 'Не зареєстровано'">
                                    mdi-account-off-outline
                                </v-icon>
                                <span>{{ item.person_id }}</span>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import personalCabinetStatsAPI from "@/utils/axios/personal_cabinet_stats"
import flatAPI from "@/utils/axios/flat"
import {round_number} from "@/utils/icons"
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {mapGetters} from "vuex";

export default {
    name: "PersonalCabinetDesktop",
    computed: {
        ...mapGetters({
            modalAnswer: 'question_answer'
        })
    },
    components: {
      AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    },
    data() {
        return {
            delete_watcher: null,
            search: '',
            flats: [],
            loading: false,
            stats: {
                flats_count: 0,
                flats_activate: 0,
                flats_active_percent: 0,
                flats_not_activate: 0,
                flats_not_active_percent: 0,
                flats_not_registered: 0,
                flats_not_registered_percent: 0
            },
            headers: [
                {text: 'Особовий рах.', value: 'person_id', width: 160},
                {text: 'Адреса', value: 'full_address'},
                {text: 'Власник', value: 'flat_owner_short'},
                {text: 'Email', value: 'email'},
            ],
            filters: {
                city: null,
                streets: [],
                buildings: [],
                status: null,
                with_email: null,
                status_list: [
                    {text: 'Активовано', value: 'Активовано'},
                    {text: 'Не активовано', value: 'Не активовано'},
                    {text: 'Не зареєстровано', value: 'Не зареєстровано'},
                ]
            },
        }
    },
    methods: {
        onCityChange(payload) {
            this.filters.city = payload.value || null
        },
        onStreetChange(payload) {
            this.filters.streets = payload.map(item => item.value) || []
        },
        onBuildingChange(payload) {
            this.filters.buildings = payload.map(item => item.value) || []
        },
        searchFlatByText(e) {
            const payload = {...this.filters, details: true}
            if (e && e.length >= 2) {
                payload.search_text = this.search
                personalCabinetStatsAPI.get_stats(payload)
                    .then(response => response.data)
                    .then(data => {
                        this.flats = data
                    })
            }

            if (!e) {
                personalCabinetStatsAPI.get_stats(payload)
                    .then(response => response.data)
                    .then(data => {
                        this.flats = data
                    })
            }
        },
        update_stats() {
            const payload = {...this.filters, details: false}
            personalCabinetStatsAPI.get_stats(payload)
                .then(response => response.data)
                .then(data => {
                    this.stats.flats_activate = 0
                    this.stats.flats_not_registered = 0
                    this.stats.flats_not_activate = 0
                    this.stats.flats_count = 0
                    this.stats.flats_not_active_percent = 0
                    this.stats.flats_not_registered_percent = 0
                    this.stats.flats_active_percent = 0

                    if (data.length) {
                        const row = data[0]
                        this.stats.flats_count = row.flats
                        this.stats.flats_not_registered = row.not_register
                        this.stats.flats_not_activate = row.not_active
                        this.stats.flats_activate = row.active
                        this.stats.flats_active_percent = round_number(row.active / row.flats * 100, 2)
                        this.stats.flats_not_registered_percent = round_number(row.not_register / row.flats * 100, 2)
                        this.stats.flats_not_active_percent = round_number(row.not_active / row.flats * 100, 2)
                    }
                })
        },
        accept_filter() {
            const payload = {...this.filters, details: true}
            personalCabinetStatsAPI.get_stats(payload)
                .then(response => response.data)
                .then(data => {
                    this.flats = data
                })
        },
        send_codes() {
            const payload = {
                filters: {...this.filters, details: true, with_email: true, status: 'Не зареєстровано'},
                url: window.location.origin
            }
            this.loading = true
            flatAPI.sendPersonalCabinetCodes(payload)
                .then(response => response.data)
                .then(data => {
                    if (data.status === 'OK') {
                        this.$store.commit(ALERT_SHOW, {
                            text: data.text,
                            color: 'success'
                        })
                    }
                })
                .catch(err => {
                    const error = err.response.data.detail;
                    this.$store.commit(ALERT_SHOW, {text: error, color: 'error lighten-1'})
                })
                .finally(() => {
                    this.loading = false
                })
        },
        deleteDialog() {
            const payload = {
                text: `Підтвердіть розсилання запрошень для реєстрації в особистому кабінеті.
                Увага! Розсилка здійснюється за обраними параметрами відображення,
                ігнорується лише статус (встановлюється автоматично у значення "Не зареєстровано")`,
                accept_button: true,
                id: 'do_send_invitation_codes'
            }
            this.$store.dispatch(QUESTION_SHOW, payload)
        },
        watch_modal_answer() {
            this.delete_watcher = this.$watch(
                'modalAnswer',
                {
                    handler(payload) {
                        if (payload.id === 'do_send_invitation_codes') {
                            if (payload.answer) {
                                const payload_1 = {
                                    text: `Необхідне остаточне підтвердження початку розсилки запрошень`,
                                    accept_button: true,
                                    id: 'do_send_invitation_codes_last'
                                }
                                this.$store.dispatch(QUESTION_SHOW, payload_1)
                            }
                        }
                        if (payload.id === 'do_send_invitation_codes_last') {
                            if (payload.answer) {
                                this.send_codes()
                            }
                        }
                    }
                }
            )
        },
    },
    created() {
        this.update_stats()
        this.accept_filter()
        this.watch_modal_answer()
    },
    beforeDestroy() {
        if (this.delete_watcher) {
            this.delete_watcher()
        }
    }
}
</script>

<style scoped lang="scss">
.card-percent {
  font-size: 2.2rem;
  font-weight: bold;
  color: #4c4c4c
}

.card-count {
  font-size: 1rem;
  color: #1a1a1a;
}
</style>